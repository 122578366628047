// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2499 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 840px;

  .backstretch {
    &:after {
      @include before(100%, 467px);
      background: url(/i/design/gradient-btm.png) center center no-repeat;
      bottom: 0;
      left: 0;

      @media only screen and (max-width: 990px) {
        background: url(/i/design/gradient-btm.png) bottom left no-repeat;
      }
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 450px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(18, 60, 147, 1) 100%);
    top: 44px;
    left: 0;
    display: none;

    @media only screen and (max-width: 990px) {
      display: block;
    }
  }


  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 160px;
  margin: 0 auto;

  @media only screen and (max-width: 990px) {
    bottom: 60px;
  }

  .wrapper {
    width: 85%;

    h2 {
      text-align: right;
      color: #fff;
      font-size: 48px;
      line-height: 48px;
      text-transform: uppercase;
      font-weight: 700;

      @media only screen and (max-width: 990px) {
        text-align: center;
        font-size: 40px;
        line-height: 0.95;
      }

      @media only screen and (max-width: 500px) {
        font-size: 35px;
      }
    }
  }
}