// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #262D92;
$h2-color: #01B7FF;
$h3-bg-color: #262D92;
$h4-bg-color: #01B7FF;
$main-blue: #262D92;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Montserrat', sans-serif;
}

.body_2499 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_2499 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_2499 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		background: $main-blue;
		width: 100%;
		height: 126px;
		left: 0;
		top: 0;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;

	width: 85%;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 10px;

	@media only screen and (max-width: 990px) {
		width: fit-content;
		top: 130px;
		margin: 0 auto;
		left: 10px;
	}

	a {
		span {}

		img#logo {}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0px;
	top: 25px;
	z-index: 99;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 60px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;
		transition: 0.5s;

		&:hover {
			translate: 0 -4px;
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		#twitter-head {
			@include box(25px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/twitter.png) no-repeat center;
		}

		//	#instagram-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_2499 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// HOME BTNS SEC
// -----------------------------------//

#home-btns {
	width: 100%;
	position: absolute;
	top: -145px;
	z-index: 10;
	padding: 0 20px;

	@media only screen and (max-width: 990px) {
		top: auto;
		position: relative;
		padding: 0;
	}

	ul {
		display: flex;
		width: 100%;
		justify-content: center;
		gap: 25px;

		@media only screen and (max-width: 1050px) {
			gap: 10px;
		}

		@media only screen and (max-width: 990px) {
			gap: 0;
			flex-wrap: wrap;
		}

		li {
			position: relative;
			background: $main-blue;
			height: 145px;
			padding: 0;
			margin: 0;
			overflow: hidden;
			max-width: 387px;
			border-radius: 50px 50px 0 0;
			width: calc(100%/4);
			background-blend-mode: luminosity;
			background-repeat: no-repeat;
			background-size: cover;
			transition: 0.4s;

			&:hover {
				filter: brightness(110%);
				border-radius: 30px 30px 0 0;

				@media only screen and (max-width: 500px) {
					border-radius: 0;
				}


				a {

					span {
						translate: 0 -4px;
					}
				}
			}

			@media only screen and (max-width: 990px) {
				border-radius: 0;
				width: calc(100%/2);
				max-width: none;
				background-position: center center !important;
			}

			@media only screen and (max-width: 500px) {
				width: 100%;
			}

			&:before {
				position: absolute;
				content: "";
				background: linear-gradient(180deg, rgba(18, 60, 147, 1) 80%, rgba(0, 195, 255, 1) 100%);
				height: 100%;
				width: 100%;
				opacity: 0.8;

				@media only screen and (max-width: 990px) {
					background: linear-gradient(180deg, #123c93 50%, #00c3ff 100%);
				}
			}

			&:nth-of-type(1) {
				background-image: url(/i/graphics/hp-photo-btns/1.jpg);
				background-position: center center;
			}

			&:nth-of-type(2) {
				background-image: url(/i/graphics/hp-photo-btns/2.jpg);
				background-position: 0 center;
			}

			&:nth-of-type(3) {
				background-image: url(/i/graphics/hp-photo-btns/3.jpg);
				background-position: center -30px;
			}

			&:nth-of-type(4) {
				background-image: url(/i/graphics/hp-photo-btns/4.jpg);
				background-position: center -110px;
			}


			a {
				display: block;
				height: 100%;
				position: relative;


				span {
					color: #fff;
					text-transform: uppercase;
					font-weight: 600;
					font-size: 24px;
					position: absolute;
					width: 100%;
					bottom: 20px;
					text-align: center;
					transition: 0.6s;

					@media only screen and (max-width: 1100px) {
						bottom: auto;
						line-height: 145px;
					}

				}
			}
		}
	}
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 100px 0;
	background: #fff;
	position: relative;
	overflow: hidden;

	@media only screen and (max-width: 990px) {
		padding: 60px 0;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/sparkle.png);
		height: 421px;
		width: 332px;
		left: 60px;
		top: 240px;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/blue-square.png);
		height: 814px;
		width: 814px;
		right: -300px;
		bottom: -280px;
		border-radius: 190px;
		z-index: 0;

		@media only screen and (max-width: 1700px) {
			right: -400px;
		}

		@media only screen and (max-width: 1250px) {
			right: -460px;
			bottom: -340px;
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {
		padding: 0 170px 0 0;
		z-index: 10;

		@media only screen and (max-width: 1600px) {
			padding: 0 300px 0 0;
		}

		@media only screen and (max-width: 1170px) {
			padding: 0;
		}

		@media only screen and (max-width: 990px) {
			text-align: center;
		}

		h2 {
			text-transform: uppercase;
			color: $main-blue;
			font-size: 48px;
			line-height: 48px;
			font-weight: 700;
			margin: 0 0 40px 0;

			@media only screen and (max-width: 500px) {
				font-size: 40px;
				line-height: normal;
			}

		}

		p {
			margin: 0 0 20px 0;
		}

	}

	.welcome-img {
		position: absolute;
		right: 90px;
		bottom: 100px;
		z-index: 10;

		@media only screen and (max-width: 1700px) {
			right: 40px;
		}

		@media only screen and (max-width: 1360px) {
			right: -10px;
			bottom: 150px;
		}

		@media only screen and (max-width: 1170px) {
			position: relative;
			right: auto;
			bottom: auto;
		}

		img {
			@media only screen and (max-width: 1800px) {
				width: 450px;
				height: 450px;
			}

			@media only screen and (max-width: 1250px) {
				width: 400px;
				height: 400px;
			}

			@media only screen and (max-width: 1170px) {
				height: auto;
				width: auto;
				margin: 0 auto;
			}
		}
	}
}

// -----------------------------------//
//  PARALLAX SEC
// -----------------------------------//

#parallax-sec {
	padding: 100px 0 200px 0;
	background: linear-gradient(90deg, rgba(0, 181, 255, 0.6) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 181, 255, 0.6) 100%);
	position: relative;

	@media only screen and (max-width: 990px) {
		padding: 60px 0;
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/welcome-top.png) center center;
		width: 1927px;
		height: 105px;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {

		p {
			text-align: center;
			color: #fff;
			font-size: 36px;
			line-height: 48px;
			font-weight: 600;

			@media only screen and (max-width: 500px) {
				font-size: 32px;
				line-height: 1.02;
			}
		}
	}
}


#vision-ethos-sec {
	padding: 50px 0 120px 0;
	position: relative;
	background: url(/i/design/welcome-bg.jpg) center top;

	@media only screen and (max-width: 990px) {
		background: #01B7FF;
		padding: 60px 0;
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/news-top.png) center center;
		width: 1927px;
		height: 87px;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {

		h2 {
			text-align: center;
			color: #fff;
			font-size: 48px;
			line-height: 48px;
			font-weight: 700;
			margin: 0 0 60px 0;

			@media only screen and (max-width: 500px) {
				font-size: 40px;
				line-height: normal;
			}

		}

		.vision-ethos-content {
			columns: 2;

			@media only screen and (max-width: 880px) {
				columns: unset;
				text-align: center;
			}

			p {
				color: #fff;
				font-size: 24px;
				line-height: 30px;

				@media only screen and (max-width: 880px) {
					margin: 0 0 20px 0;
					line-height: normal;
				}

				@media only screen and (max-width: 500px) {

					font-size: 18px;
				}
			}
		}

		.read-more {
			background: $main-blue;
			color: #fff;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 600;
			height: 70px;
			width: 269px;
			border-radius: 35px;
			display: block;
			line-height: 70px;
			text-align: center;
			margin-left: auto;
			transition: 0.4s;

			&:hover {
				background: #fff;
				color: $main-blue;
				box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
			}

			@media only screen and (max-width: 880px) {
				margin-left: 0;
				margin: 0 auto;
				margin-top: 40px;
			}
		}
	}
}

// -----------------------------------//
// NEWS DIARY SEC
// -----------------------------------//

#news-diary-sec {
	padding: 20px 0 80px 0;
	position: relative;
	background: url(/i/design/news-bg.jpg) center top;

	@media only screen and (max-width: 990px) {
		padding: 60px 0;
		background: $main-blue;
	}

	.wrapper {

		@media only screen and (max-width: 990px) {
			width: 90%;
		}

		h2 {
			text-align: left;
			color: #fff;
			font-size: 48px;
			line-height: 48px;
			font-weight: 700;
			margin: 0 0 50px 0;

			@media only screen and (max-width: 990px) {
				text-align: center;
			}

			@media only screen and (max-width: 500px) {
				font-size: 40px;
				line-height: normal;
			}

		}

		#SideHeadingNews {

			ul.SideList {

				display: grid;
				grid-template-columns: repeat(2, 1fr) repeat(3, 0);
				grid-template-rows: repeat(2, 1fr) repeat(3, 0);
				grid-column-gap: 0px;
				grid-row-gap: 0px;

				@media only screen and (max-width: 990px) {
					display: grid;
					grid-template-columns: 1fr repeat(4, 0);
					grid-template-rows: repeat(3, 1fr) repeat(2, 0);
					grid-column-gap: 0px;
					grid-row-gap: 0px;
					justify-items: center;
				}

				li {
					position: relative;
					display: inline-block;
					overflow: hidden;
					transition: 0.4s;

					&:hover {
						filter: brightness(120%);
					}

					&:before {
						position: absolute;
						content: "";
						background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
						width: 100%;
						height: 100%;
						bottom: 0;
						left: 0;
						border-radius: 10px;
						pointer-events: none;
						z-index: 1;
					}

					&:nth-of-type(1) {
						width: 650px;
						height: 415px;
						// float: left;
						margin: 0 25px 0 0;

						grid-area: 1 / 1 / 3 / 2;

						@media only screen and (max-width: 990px) {
							grid-area: 1 / 1 / 2 / 2;
							width: auto;
							height: auto;
							margin: 0;
							;
						}

						a {

							.inner {
								padding: 40px;
							}
						}
					}

					&:nth-of-type(2) {
						width: 306px;
						height: 195px;
						margin: 0 0 23px 0;

						grid-area: 1 / 2 / 2 / 3;

						@media only screen and (max-width: 990px) {
							grid-area: 2 / 1 / 3 / 2;
							width: auto;
							height: auto;
							margin: 0;
						}

						a {

							.inner {
								padding: 20px;

								p.desc {
									-webkit-line-clamp: 1;
								}
							}
						}

					}

					&:nth-of-type(3) {
						width: 306px;
						height: 195px;

						grid-area: 2 / 2 / 3 / 3;

						@media only screen and (max-width: 990px) {
							grid-area: 3 / 1 / 4 / 2;
							width: auto;
							height: auto;
							margin: 0;
						}

						a {

							.inner {
								padding: 20px;

								p.desc {
									-webkit-line-clamp: 1;
								}
							}
						}
					}

					@media only screen and (max-width: 990px) {
						margin: 0 0 20px 0 !important;
						max-width: 440px;
						height: 280px;

					}

					a {
						position: relative;

						.inner {
							position: absolute;
							bottom: 0;
							z-index: 10;

							p.date {
								position: relative;
								color: #fff;
								font-size: 14px;
								font-weight: 600;
								display: block;

								span {}
							}

							p.title {
								position: relative;
								color: #00B7FF;
								font-size: 16px;
								font-weight: 600;
								display: block;

								span {
									overflow: hidden;
									-webkit-line-clamp: 1;
									height: auto !important;
									text-overflow: ellipsis;
								}
							}

							p.desc {
								position: relative;
								color: #fff;
								font-size: 16px;
								line-height: 18px;
								overflow: hidden;
								display: -webkit-inline-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								text-overflow: ellipsis;
								margin: 15px 0 0 0;
								word-wrap: break-word;
							}
						}

						img {
							min-width: 100%;
							min-height: 100%;

							object-fit: cover;
							border-radius: 10px;
						}
					}
				}
			}
		}

		.btn-wrap {
			display: flex;
			justify-content: center;
			gap: 30px;
			margin: 50px 0 0 0;

			@media only screen and (max-width: 500px) {
				flex-direction: column;
				align-items: center;
			}

			.more {
				background: #00B7FF;
				color: #fff;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 600;
				height: 70px;
				width: 269px;
				border-radius: 35px;
				display: block;
				line-height: 70px;
				text-align: center;
				transition: 0.4s;

				&:hover {
					background: #fff;
					color: $main-blue;
					box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

.Content1Column,
.Content2Column,
.Content3Column {
	border-radius: 20px;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
}

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: linear-gradient(180deg, rgba(18, 60, 147, 1) 50%, rgba(0, 195, 255, 1) 100%);
	border-radius: 20px;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #fff;
	color: #000;
	position: relative;
	z-index: 10;

	a {
		color: #000;

		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 990px) {
		padding: 50px 0;
	}

	.wrapper {
		text-align: left;
		font-size: 16px;
		display: flex;
		padding: 50px 0;
		align-items: flex-end;

		@media only screen and (max-width: 1050px) {
			flex-direction: column;
			align-items: center;
			gap: 15px;
		}

		@media only screen and (max-width: 990px) {
			padding: 0;
		}

		.left,
		.center,
		.right {
			width: calc(100%/3);

			@media only screen and (max-width: 1050px) {
				width: 100%;
			}
		}

		.left {
			line-height: 30px;

			@media only screen and (max-width: 1050px) {
				text-align: center;
			}

			p.address {

				span {
					display: block;
					font-weight: 600;
				}
			}
		}

		.center {
			padding: 0 0 0 50px;

			@media only screen and (max-width: 1050px) {
				text-align: center;
				padding: 0;
			}

			p.telephone {
				padding: 0 0 40px 0;

				@media only screen and (max-width: 1050px) {
					padding: 0;
				}
			}

			a[href^="mailto:"] {
				display: block;
			}
		}

		.right {
			text-align: right;
			font-size: 12px;
			line-height: 22px;
			text-transform: uppercase;
			font-weight: 500;

			@media only screen and (max-width: 1050px) {
				text-align: center;
			}

			p {}

			a {}

			#copyright,
			#credit {
				a {}
			}

			#copyright {}

			#credit {}
		}
	}

	p,
	a[href^="mailto:"] {
		margin: 0;

	}

	.map {
		height: 230px;
		width: 100%;

		@media only screen and (max-width: 990px) {
			display: none;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;