section#social {
	width: 100%;
	height: 100% !important;
	position: fixed;
	top: 0;
	left: auto;
	visibility: hidden;
	z-index: 99999;
	background: rgba(0, 0, 0, .8);

	@media only screen and (max-width: 1170px) {
		display: none;
	
		section#social #sidebar {}
	
		section#social #sidebar h2 {}
	}

	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#sidebar {
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		z-index: 99999;

		background: $main-blue;

		height: 269px;
		width: 70px;
		border-radius: 35px;

		left: -75px;
		top: 50%;
		transform: translateY(-50%);

		h2 {
			// @include center;
			position: absolute;
			color: #fff;
			white-space: nowrap;
			transform: rotate(90deg);
			font-size: 16px;
			text-transform: uppercase;
			top: 39%;
			left: -57px;
			font-weight: 600;
		}
	}
}

#close-social {
	position: absolute;
	top: 15px;
	right: 30px;
	font-size: 13px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
}

#close-social img {
	padding: 14px 13px 13px 13px;
	border-radius: 50%;
	margin-bottom: 6px;
}

#close-social:hover {
	opacity: .7;
}

#close-social:hover img {
	opacity: .7;
}

.backstretch {
	height: 100%;
}
